var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider',{staticClass:"mt-6",attrs:{"id":"dividerfiltertop"}}),_c('div',{class:("" + _vm.classheaderfilters)},[_c('v-card',{ref:"headerfilters",class:"d-flex justify-space-between px-1",style:(("margin-top: " + (this.panelTotales.length > 0 && _vm.classheaderfilters != ''
            ? '-327px'
            : _vm.classheaderfilters != ''
            ? '43px'
            : '0px') + ";")),attrs:{"id":"headerfilters","flat":"","tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-card',{staticClass:"py-2 d-flex",attrs:{"flat":"","tile":""}},[_c('div',[_c('span',{staticClass:"caption font-weight-medium"},[_vm._v("T. PPTO:")]),(_vm.selectedTipoPresupuesto)?_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.selectedTipoPresupuesto.text)+" ")]):_vm._e()],1),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"caption font-weight-medium",staticStyle:{"margin-top":"1.75px"}},[_vm._v("SEDE:")]),_vm._l((_vm.selectedSedes),function(item,index){return _c('div',{key:index},[(index < 1)?_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()],1)}),(_vm.selectedSedes.length > 1)?_c('span',{staticClass:"grey--text text-caption"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("(+"+_vm._s(_vm.selectedSedes.length - 1)+")")])]}}],null,false,703425179)},_vm._l((_vm.selectedSedes),function(item,index){return _c('div',{key:index},[(index >= 1)?_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])}),0)],1):_vm._e()],2),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"caption font-weight-medium",staticStyle:{"margin-top":"1.75px"}},[_vm._v("ÁREA:")]),_vm._l((_vm.selectedSubAreas),function(item,index){return _c('div',{key:index},[(index < 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.codigo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e()],1)}),(_vm.selectedSubAreas.length > 1)?_c('span',{staticClass:"grey--text text-caption"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("(+"+_vm._s(_vm.selectedSubAreas.length - 1)+")")])]}}],null,false,1237408287)},_vm._l((_vm.selectedSubAreas),function(item,index){return _c('div',{key:index},[(index >= 1)?_c('span',[_vm._v(" "+_vm._s(item.codigo)+": "+_vm._s(item.text)+" ")]):_vm._e()])}),0)],1):_vm._e()],2),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"caption font-weight-medium",staticStyle:{"margin-top":"1.75px"}},[_vm._v("CUENTA MAYOR:")]),_vm._l((_vm.selectedCuentasMayores),function(item,index){return _c('div',{key:index},[(index < 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.descripcion)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.descripcion))])]):_vm._e()],1)}),(_vm.selectedCuentasMayores.length > 1)?_c('span',{staticClass:"grey--text text-caption"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("(+"+_vm._s(_vm.selectedCuentasMayores.length - 1)+")")])]}}],null,false,3221473370)},_vm._l((_vm.selectedCuentasMayores),function(item,index){return _c('div',{key:index},[(index >= 1)?_c('span',[_vm._v(" "+_vm._s(item.descripcion)+" ")]):_vm._e()])}),0)],1):_vm._e()],2),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('div',[_c('span',{staticClass:"caption font-weight-medium"},[_vm._v("PERIODO:")]),(_vm.periodoInicio != null && _vm.periodoFin != null)?_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.periodoInicio)+" - "+_vm._s(_vm.periodoFin)+" ")]):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('cargarGraficos')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('openFilter')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-menu")])],1)]}}])},[_c('span',[_vm._v("Filtros")])])],1)],1)],1),_c('v-divider',{staticClass:"my-0 mb-3"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }