<template>
  <div>
    <v-divider class="mt-6" id="dividerfiltertop"></v-divider>
    <div :class="`${classheaderfilters}`">
      <v-card
        :class="`d-flex justify-space-between px-1`"
        ref="headerfilters"
        id="headerfilters"
        flat
        tile
        :style="
          `margin-top: ${
            this.panelTotales.length > 0 && classheaderfilters != ''
              ? '-327px'
              : classheaderfilters != ''
              ? '43px'
              : '0px'
          };`
        "
      >
        <v-row>
          <v-col cols="11">
            <v-card class="py-2 d-flex" flat tile>
              <div>
                <span class="caption font-weight-medium">T. PPTO:</span>
                <v-chip class="mx-1" label small v-if="selectedTipoPresupuesto">
                  {{ selectedTipoPresupuesto.text }}
                </v-chip>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div class="d-flex">
                <span class="caption font-weight-medium" style="margin-top: 1.75px;">SEDE:</span>
                <div v-for="(item, index) in selectedSedes" :key="index">
                  <v-chip v-if="index < 1" class="mx-1" label small>
                    {{ item.text }}
                  </v-chip>
                </div>
                <span v-if="selectedSedes.length > 1" class="grey--text text-caption">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">(+{{ selectedSedes.length - 1 }})</span>
                    </template>
                    <div v-for="(item, index) in selectedSedes" :key="index">
                      <span v-if="index >= 1">
                        {{ item.text }}
                      </span>
                    </div>
                  </v-tooltip>
                </span>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div class="d-flex">
                <span class="caption font-weight-medium" style="margin-top: 1.75px;">ÁREA:</span>
                <div v-for="(item, index) in selectedSubAreas" :key="index">
                  <v-tooltip bottom v-if="index < 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip class="mx-1" v-bind="attrs" v-on="on" label small>
                        {{ item.codigo }}
                      </v-chip>
                    </template>
                    <span>{{ item.text }}</span>
                  </v-tooltip>
                </div>
                <span v-if="selectedSubAreas.length > 1" class="grey--text text-caption">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">(+{{ selectedSubAreas.length - 1 }})</span>
                    </template>
                    <div v-for="(item, index) in selectedSubAreas" :key="index">
                      <span v-if="index >= 1"> {{ item.codigo }}: {{ item.text }} </span>
                    </div>
                  </v-tooltip>
                </span>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div class="d-flex">
                <span class="caption font-weight-medium" style="margin-top: 1.75px;">CUENTA MAYOR:</span>
                <div v-for="(item, index) in selectedCuentasMayores" :key="index">
                  <v-tooltip bottom v-if="index < 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip class="mx-1" v-bind="attrs" v-on="on" label small>
                        {{ item.descripcion }}
                      </v-chip>
                    </template>
                    <span>{{ item.descripcion }}</span>
                  </v-tooltip>
                </div>
                <span v-if="selectedCuentasMayores.length > 1" class="grey--text text-caption">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">(+{{ selectedCuentasMayores.length - 1 }})</span>
                    </template>
                    <div v-for="(item, index) in selectedCuentasMayores" :key="index">
                      <span v-if="index >= 1"> {{ item.descripcion }} </span>
                    </div>
                  </v-tooltip>
                </span>
              </div>
              <v-divider vertical class="mx-3"></v-divider>
              <div>
                <span class="caption font-weight-medium">PERIODO:</span>
                <v-chip class="mx-1" v-if="periodoInicio != null && periodoFin != null" label small>
                  {{ periodoInicio }} - {{ periodoFin }}
                </v-chip>
              </div>
            </v-card>
          </v-col>
          <v-col cols="1" class="d-flex align-center justify-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="$emit('cargarGraficos')">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="$emit('openFilter')">
                  <v-icon>mdi-filter-menu</v-icon>
                </v-btn>
              </template>
              <span>Filtros</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
      <v-divider class="my-0 mb-3"></v-divider>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeaderFilter',
  props: {
    panelTotales: {
      type: Array,
      default: () => []
    },
    selectedTipoPresupuesto: {
      type: Object,
      default: null
    },
    selectedSedes: {
      type: Array,
      default: () => []
    },
    selectedSubAreas: {
      type: Array,
      default: () => []
    },
    selectedCuentasMayores: {
      type: Array,
      default: () => []
    },
    periodoInicio: {
      type: Number,
      default: null
    },
    periodoFin: {
      type: Number,
      default: null
    },
    classheaderfilters: {
      type: String,
      default: ''
    }
  },
  watch: {},
  computed: {
    panelTotalesAux: {
      get() {
        return this.panelTotales;
      },
      set(val) {
        this.$emit('expand', val);
      }
    }
  }
};
</script>

<style lang="scss">
.header-filters-expand {
  position: fixed !important;
  z-index: 4;
  width: calc(100% - 304px);
  margin-left: -16px;
  margin-top: -199px;
}

.header-filters-not-expand {
  position: fixed !important;
  z-index: 4;
  width: calc(100% - 118px);
  margin-left: -16px;
  margin-top: -199px;
}

.header-filters-expand div .row,
.header-filters-not-expand div .row {
  padding: 0 15px;
}

.header-filters-expand .v-divider,
.header-filters-not-expand .v-divider {
  border-color: #00000038;
}

.header-filters div {
  position: relative !important;
  display: flex !important;
}
</style>
